<template>
    <ul class="nav">
        <li class="nav-item" @click="goHome">
            <router-link :class="['nav-link', activePage === 'home' ? 'active' : '']" to="/" title="Zurück zur Startseite">
                <span class="material-icons-outlined" aria-hidden="true">cottage</span> Start 
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :class="['nav-link', activePage === 'orte' ? 'active' : '']" to="/orte" title="Zu der Übersicht über die Orte">
                <span class="material-icons-outlined" aria-hidden="true">place</span> Orte 
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :class="['nav-link', activePage === 'angebote' ? 'active' : '']" to="/angebote" title="Zu der Übersicht über die Angebote">
                <span class="material-icons-outlined" aria-hidden="true">calendar_today</span> Angebote 
            </router-link>
        </li>
        <li class="nav-item">
            <router-link :class="['nav-link', activePage === 'ratgeber' ? 'active' : '']" to="/ratgeber" title="Zu der Übersicht über die Ratgeberartikel">
                <span class="material-icons-outlined" aria-hidden="true">help_outline</span> Ratgeber 
            </router-link>
        </li>
        <li class="nav-item" v-click-outside="{handler: 'hideShowMoreMenu'}" @keyup.esc="hideShowMoreMenu">
            <button :class="['nav-link','no-btn', activePage === 'mehr' ? 'active' : '']" @click="toggleShowMoreMenu" title="Mehr Menüpunkte anzeigen" >
                <span class="material-icons-outlined" aria-hidden="true">more_vert</span> Mehr 
            </button>
            <ul v-if="showMoreMenu" class="custom-dropdown" @click="hideShowMoreMenu" @keyup.enter="hideShowMoreMenu">
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/category/Beratung &amp; Hilfe" @click="hideShowMoreMenu" @keyup.enter="hideShowMoreMenu" title="Zu der Übersicht der Beratungs- und Hilfsangebote">
                        <div class="icon">
                            <img src="/assets/icons/menu-icon-1.png" alt="Beratung &amp; Hilfe" class="icon">
                        </div> Beratung &amp; Hilfe 
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>

                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/category/Freizeit" @click="hideShowMoreMenu" @keyup.enter="hideShowMoreMenu" title="Zu der Übersicht der Freizeitsangebote">
                        <div class="icon">
                            <img src="/assets/icons/menu-icon-2.png" alt="Freizeit" class="icon">
                        </div> Freizeit 
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/category/Ausbildung &amp; Weiterbildung" title="Zu der Übersicht der Aus- und Weiterbildungsangebote">
                        <div class="icon">
                            <img src="/assets/icons/menu-icon-3.png" alt="Ausbildung &amp; Weiterbildung" class="icon">
                        </div> Ausbildung &amp; Weiterbildung
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/merkliste" title="Zu der Merklistenübersicht">
                        <div class="icon"><span class="material-icons-outlined icon" aria-hidden="true">bookmark_border</span>
                        </div> Merklisten
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link','seprator', activePage === 'mehr' ? 'active' : '']" to="/bildung"  title="Zu dem Kita- und Schulfinder">
                        <div class="icon">
                            <span class="material-icons-outlined icon" aria-hidden="true">place</span>
                        </div> Kita- und Schulfinder
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/nutzungsbedingungen" title="Zu den Nutzungsbedingungen">
                        <div class="icon">
                            <span class="material-icons-outlined icon" aria-hidden="true">description</span>
                        </div> Nutzungsbedingungen
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/datenschutz" title="Zu der Datenschutzerklärung">
                        <div class="icon">
                            <span class="material-icons-outlined icon" aria-hidden="true">shield</span>
                        </div> Datenschutzerklärung
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/barrierefrei" title="Zu der Barrierefreiheitserklärung">
                        <div class="icon">
                            <span class="material-icons-outlined icon" aria-hidden="true">accessibility_new</span>
                        </div> Barrierefreiheitserklärung
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li class="dropdown-navitem">
                    <router-link :class="['dropdown-navitem__link', activePage === 'mehr' ? 'active' : '']" to="/impressum" title="Zu dem Impressum">
                        <div class="icon">
                            <span class="material-icons-outlined icon" aria-hidden="true">person</span>
                        </div> Impressum 
                        <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                        <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
                    </router-link>
                </li>
                <li v-if="!userLoggedIn && !$hideUserAccountRelatedFunctions" class="dropdown-navitem">
                  <router-link class="dropdown-navitem__link" :to="{ name: 'Login'}">
                    <div class="icon">
                      <span class="material-icons-outlined">person</span>
                  </div>
                  Login
                  <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
                  <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
              </router-link>                
          </li>
          <li v-if="userLoggedIn && !$hideUserAccountRelatedFunctions" class="dropdown-navitem">
              <router-link class="dropdown-navitem__link" :to="{ name: 'Profile'}">
                <div class="icon">
                  <span class="material-icons-outlined icon">person</span>
              </div>
              Mein Profil
              <span class="material-icons ml-auto show-ltr" aria-hidden="true">navigate_next</span>
              <span class="material-icons ml-auto show-rtl" aria-hidden="true">navigate_before</span>
          </router-link>                
      </li>
      <li v-if="userLoggedIn && !$hideUserAccountRelatedFunctions" class="dropdown-navitem seprator">
          <button class="btn btn-active logout-btn btn-w-icon icon-left" @click.prevent="logOut">
            <span class="material-icons-outlined inline-icon">lock</span>
            <span>Abmelden</span>
        </button>
    </li>

      <li class="dropdown-navitem">
          <a href="https://cms.wolfsburgerlupe.de/#/" target="_blank" class="btn btn-active mt10 mb20">
            <span>Login für Anbietende</span>
        </a>
    </li>

</ul>

</li>
</ul>
</template>
<script>
  import clickOutside from '@/directives/click-outside';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
   name: 'DesktopNav',
   directives: { clickOutside },
   data() {
    return {
        showMoreMenu: false,
    }
},
computed: {
    activePage() {
        if (this.$route.path === '/') {
            return 'home';
        } else if (this.$route.path.includes('orte')) {
            return 'orte';
        } else if (this.$route.path.includes('angebote')) {
            return 'angebote';
        } else if (this.$route.path.includes('ratgeber')) {
            return 'ratgeber';
        }

        return 'mehr';
    },
    userLoggedIn() {
        if(this.$store.getters.getToken){
          return true;
      }
      return false;
  },
},
methods: {
    showLoader,
    hideLoader,
    logOut(){
        this.loader = this.showLoader(this.loader);
        this.$store.dispatch('logout');

        this.$notify({
          duration: 3500,
          title: 'Erfolgreich abgemeldet!',
          text: 'Sie haben sich von der Wolfsburger Lupe abgemeldet.'
      });

        this.loader = this.hideLoader(this.loader);
        this.$router.push({name:'Home'});
    },
    goHome(){
        if(this.route == "/"){
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        else{
            this.$router.push('/')
        }
    },
    hideShowMoreMenu(){
        this.showMoreMenu = false;
    },
    toggleShowMoreMenu(){
        this.showMoreMenu = !this.showMoreMenu;
    }
}
}

</script>
<style lang="scss" scoped>

    header .header-bottom .navbar .nav-item{
        @media (max-width: 1046px) {
            margin-left: 50px;
        }
    }

    .custom-dropdown{
        display: block;
        position: absolute;
        width: 290px;
        background: #FFFFFF;
        top: 28px;
        right: 0;
        box-shadow: 0px 2px 8px 0px #00000040;
        padding: 0 15px;
    }

    .logout-btn {
        margin-top: 15px;
        margin-bottom: 15px;
        width: 100%;
    }

        body[dir="rtl"] {
      .custom-dropdown {
        right: -220px;
      }
    }

</style>
