import clickoutside from './clickoutside';

//v-click-outside="{exclude: ['button'], handler: 'closeTimePicker'}">

const install = function(Vue) {
  Vue.directive('click-outside', clickoutside);
};

if (window.Vue) {
  window['click-outside'] = clickoutside;
  Vue.use(install); // eslint-disable-line
}

clickoutside.install = install;
export default clickoutside;
